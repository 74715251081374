* {
  box-sizing: border-box;
}

body {
  background-color: rgb(66, 66, 66);
  color: white;
}

.CollectionListNavList {
  display: flex;
  justify-content: space-around;
  padding-left: 0px;
  margin-top: 50px;
  flex-direction: column;
  text-align: center;
}

.ItemListNavCollectionLink {
  background: rgb(37, 37, 37);
  border-radius: 6px;
  margin: 25px auto;
  padding: 10px;
  border: #a8a8a8 2px solid;
  text-align: center;
  color: white;
}

li {
  list-style-type: none;
  margin-bottom: 30px;
}

.ItemListNavButtonWrapper {
  display: flex;
  justify-content: space-evenly;
  margin: 25px auto;
}

.itemListNavAddCollectionButton {
  background: rgb(157, 121, 255);
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  margin: 0 auto;
  font-size: 1em;
  -webkit-appearance: inherit;
}

a {
  text-decoration: none;
}
