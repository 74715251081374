input[type="text"],
input[type="number"],
textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 5px 22px 0;
  display: inline-block;
  border: 1px solid rgb(157, 121, 255);
  background: #f1f1f1;
  font-size: 1em;
}

.AddItemForm {
  background: rgb(37, 37, 37);
  width: 97%;
  border-radius: 6px;
  margin: 20px auto;
  padding: 30px;
  border: rgb(157, 121, 255) 4px solid;
  text-align: center;
}

.AddItemButton {
  font-size: 1em;
  background: rgb(157, 121, 255);
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  border: none;
}

.error {
  display: none;
}

@media screen and (min-width: 765px) {
  .AddItemForm {
    width: 40%;
  }
}
