* {
    box-sizing: border-box;
}
body {
    background-color: rgb(66, 66, 66);
    color: white;
}

.App {
    background-color: rgb(66, 66, 66);
    color: white;
    margin: 0 auto;
}